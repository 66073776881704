export default function Footer() {
    return (
        <footer className="pt-40 pb-10">
            <div className="flex flex-row items-center justify-center space-x-10 text-[28px]">
                <a href="https://twitter.com/WeebFi" target="_blank">
                    <i className="bi bi-twitter"></i>
                </a>
                <a href="https://t.me/WeebFi" target="_blank">
                    <i className="bi bi-telegram"></i>
                </a>
                {/* <i class="bi bi-github"></i>
                <i class="bi bi-discord"></i> */}
            </div>
        </footer>
    );
}
