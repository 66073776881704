import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import Links from "./Links";

export default function Navbar() {
    const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

    function toggleNavbar() {
        setIsNavbarCollapsed(!isNavbarCollapsed);
    }

    return (
        <nav>
            <div className="flex items-center justify-between">
                <div className="flex items-center lg:space-x-16 justify-between">
                    <Link className="navbar-brand flex items-center gap-3" to="/">
                        <div className="bg-weeb-logo
                        h-[64px]
                        max-h-[64px]
                        w-[64px] 
                        max-w-[64px]
                        lg:h-[96px]
                        lg:max-h-[96px]
                        lg:w-[96px] 
                        lg:max-w-[96px]
                        bg-left-top 
                        bg-no-repeat 
                        self-center">
                        </div>
                        <div className="bg-weeb-text
                        h-[78px]
                        max-h-[78px]
                        w-[153px] 
                        max-w-[153px]
                        lg:h-[104px]
                        lg:max-h-[104px]
                        lg:w-[204px] 
                        lg:max-w-[204px]
                        bg-left-top 
                        bg-no-repeat 
                        self-center">
                        </div>
                    </Link>
                    {/* <div className="hidden lg:flex lg:space-x-4 items-center">
                        <Links />
                    </div> */}
                </div>

                <div className="flex items-center justify-end space-x-4">
                    {/* <button
                        type="button"
                        className="btn btn-rose p-2 w-40 font-semibold"
                        onClick={() => {
                            window.location.href = "https://app.weebfi.com";
                        }}
                    >
                        Go to App
                    </button> */}

                    {/* <button type="button" className={`lg:hidden rounded-md ring-4 focus:outline-none ${isNavbarCollapsed
                        ? "text-gray-100/75 ring-gray-100/5 hover:bg-gray-100/10 hover:text-gray-100 hover:ring-gray-100/25"
                        : "bg-gray-100/10 text-gray-100 ring-gray-100/25"
                        }`}
                        onClick={toggleNavbar}>
                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-10 w-12 fill-current p-1`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                        </svg>
                    </button> */}
                </div>
            </div>

            <div className={`lg:hidden ${isNavbarCollapsed
                ? "hidden"
                : "mt-2 grid w-full grid-cols-2 gap-2 rounded-lg bg-gray-100/10"
                }`}
            >
                <div className="flex flex-col p-2 gap-1">
                    <Links />
                </div>
            </div>
        </nav>
    );
}
