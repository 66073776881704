import { BrowserRouter } from "react-router-dom";

import Main from "./Main";
import Navbar from "./Navbar";
import Footer from "./Footer";

function App() {
  return (
    <BrowserRouter>
      <div className="lg:container mx-auto p-5 h-screen">
        <div className="flex flex-col justify-between h-full">
          <Navbar />
          <Main />
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
