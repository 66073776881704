import React from "react";

export default function Main() {
    const appUri = "https://app.weebfi.com";

    return (
        <div className="mt-16">
            <div className="grid grid-cols-1 gap-y-24 md:text-[18px] lg:text-[22px] xl:text-[26px]">
                <div>
                    <h1 className="text-[3.25em] font-bold leading-[1.1] md:leading-tight">
                        The easiest form of <span className="text-emerald-300">passive income</span>
                    </h1>
                    <p className="mt-5 text-[1.25em]">
                        With the world's first <span className="font-bold text-yellow-400">non-custodial</span> staking and yield farming token.
                    </p>
                </div>

                <div className="-mt-8 text-center">
                    <button
                        type="button"
                        className="btn btn-rose p-2 w-40 text-[1.25em] font-semibold"
                        onClick={() => {
                            window.location.href = appUri;
                        }}
                    >
                        Start to Earn
                    </button>
                </div>

                <div className="bg-indigo-900/40 grid grid-cols-1 gap-y-10 card bg-diagonal-lines">
                    <h3 className="text-[2em] font-bold text-emerald-100">
                        Not in your wallet, <span className="font-bold text-emerald-300">not your money!</span>
                    </h3>
                    <div>
                        <p>
                            Unlike traditional staking and yield farming where you're required to give the custody of your tokens to a secondary or a third party contract, with <span className="font-bold text-yellow-400">WEEB</span> staking and yield farming is an intrinsic feature.
                        </p>
                        <p className="mt-5">
                            You <span className="font-bold text-yellow-400">own</span> your tokens, <span className="font-bold text-red-400">always farming</span> and earning, no time constraints.
                        </p>
                        <div className="mt-5 flex items-center justify-end">
                            <a className="text-indigo-300 text-[0.8em] w-48 text-center btn btn-indigo px-3 py-1" href={appUri}>
                                Earn now
                            </a>
                        </div>
                    </div>
                </div>

                <div className="bg-violet-900/30 grid grid-cols-1 gap-y-10 card bg-diagonal-lines">
                    <h3 className="text-[2em] font-bold text-red-100">
                        Holding is incentivized on <span className="text-red-300">protocol level</span>
                    </h3>
                    <div>
                        <p>
                            The built in price tracking mechanism periodically adjusts the parameters to incentivize holding rather than selling which in return creates a price appreciation effect.
                        </p>
                        <p className="mt-5">
                            The <span className="font-bold text-yellow-400">holders</span> are <span className="font-bold text-red-400">always rewarded</span> generously.
                        </p>
                        <div className="mt-5 flex items-center justify-end">
                            <a className="text-violet-300 text-[0.8em] w-48 text-center btn btn-violet px-3 py-1" href={appUri}>
                                Claim rewards
                            </a>
                        </div>
                    </div>
                </div>

                <div className="bg-sky-900/30 grid grid-cols-1 gap-y-10 card bg-diagonal-lines">
                    <h3 className="text-[2em] font-bold text-blue-100">
                        Disinflationary model with <span className="text-emerald-300">volatility control</span> and <span className="text-blue-400">investor protection</span>
                    </h3>
                    <div>
                        <p>
                            <span className="font-bold text-yellow-400">WEEB</span> uses dual burning mechanisms, a constant rate <span className="italic font-bold text-amber-100">transfer burn</span> mechanism and a variable rate <span className="italic font-bold text-amber-100">sales burn</span> mechanism to <span className="font-bold text-red-400">suppress inflation</span>.
                        </p>
                        <p className="mt-5">
                            Unique <span className="font-bold text-yellow-400">inflation regulation</span> cycles based on time-weighted average price (TWAP) tracking allow for increased token burnings at certain conditions to create the disinflationary effect.
                        </p>
                        <p className="mt-5">
                            As the current price deviates from the TWAP (in any direction) the sales burn rate is proportionally increased to make selling less attractive and the price <span className="font-bold text-violet-400">less volatile</span> thus creating a <span className="font-bold text-red-400">protective shield</span> on the long term investor.
                        </p>
                        <div className="mt-5 flex items-center justify-end">
                            <a className="text-sky-200 text-[0.8em] w-48 text-center btn btn-sky px-3 py-1" href={appUri}>
                                Become Investor
                            </a>
                        </div>
                    </div>
                </div>

                <div className="mt-16 bg-pink-900/20 grid grid-cols-1 gap-y-10 card bg-diagonal-lines">
                    <h3 className="text-[2em] font-bold text-fuchsia-200">
                        Everybody loves <span className="text-fuchsia-400">games</span>
                    </h3>
                    <div>
                        <p>
                            Beat your opponents and <span className="font-bold text-yellow-400">earn more</span> with quick, competitive and easy games.
                        </p>
                        <div className="mt-5 flex items-center justify-end">
                            <a className="text-pink-200 text-[0.8em] w-48 text-center btn btn-pink px-3 py-1" href={appUri}>
                                Play games
                            </a>
                        </div>
                    </div>
                </div>

                <div className="mt-16 bg-emerald-900/20 grid grid-cols-1 gap-y-10 card bg-diagonal-lines">
                    <h3 className="text-[2em] font-bold text-amber-100">
                        Yield farming with the unique <span className="text-amber-300">hybrid farms</span>
                    </h3>
                    <div>
                        <p>
                            New type of yield farming comprising <span className="italic font-bold text-red-400">combination of classic yield farming and lending mechanisms</span> allows of both <span className="italic font-bold text-violet-400">over-collateralized</span> and <span className="italic font-bold text-violet-400">under-collateralized</span> models to be achieved for less risky and more risky assets.
                        </p>
                        <div className="mt-5 flex items-center justify-end">
                            <a className="text-emerald-200 text-[0.8em] w-48 text-center btn btn-emerald px-3 py-1" href={appUri}>
                                Start farming
                            </a>
                        </div>
                    </div>
                </div>

                <div className="mt-16 bg-sky-900/20 grid grid-cols-1 gap-y-10 card bg-diagonal-lines">
                    <h3 className="text-[2em] font-bold text-sky-100">
                        Protocol monitoring <span className="text-sky-400">income</span>
                    </h3>
                    <div>
                        <p>
                            Another unique model which allows of an <span className="italic font-bold text-red-400">additional income stream</span> by monitoring the farm liquidities and rebalancing the system.
                        </p>
                        <div className="mt-5 flex items-center justify-end">
                            <a className="text-sky-200 text-[0.8em] w-48 text-center btn btn-sky px-3 py-1" href={appUri}>
                                Generate income
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
