import { NavLink } from "react-router-dom";

export default function Links() {
    return (
        <>
            <NavLink className="nav-link" to="/" activeclassname="active">Home</NavLink>
            <NavLink className="nav-link" to="/Link1" activeclassname="active">Link1</NavLink>
            <NavLink className="nav-link" to="/Link2" activeclassname="active">Link2</NavLink>
            <NavLink className="nav-link" to="/Link3" activeclassname="active">Link3</NavLink>
        </>
    );
}
